<template>
<div id="UpgradeVip">  
  <Header/>
  <CommonAlert1     ref="commonAlert1" :title="title" :content="content" :btnnameup="btnNameUp"/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          升級VIP會員
        </a>
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" @click="openOrderRecord()"> 
          交易明細
        </a> 
      </div>
    </nav>
    <!-- End header -->
    <div class="nicolespacetop"></div>
    <!-- End header -->
    <div class="">
      <div class="" style="position: absolute;">
        <img class="w-100 d-md-none" src="assets/img/img061_phone.png">
        <img class="w-100 d-none d-md-block" src="assets/img/img061_pad.png">
        <div class="w-90 d-flex align-content-center justify-content-between cardname ">
          <div class="d-flex pt-2">
            <h5 class="text-white text-bolder text-card-name mb-0 me-2">{{ name }}</h5>
            <span class="badge badge-danger nicolebadge rounded-pill text-xs">{{ userLevelName }}</span>
          </div>
          <!-- 使用者頭像 -->
          <img class=" avatar rounded-circle" v-real-img="icon" src="assets/img/camera-no.png" style="object-fit: cover!important;">
        </div> 
        <div class=" cardpromote w-95">
          <div class="card mt-md-3">
            <div class="p-2 py-3">
              <div class="d-flex mb-1 ms-1">
                <!--eslint-disable vue/no-v-html-->
                <img src="assets/img/ic_vip_crown.png" class="avatar avatar-sm" v-html="'&nbsp;'">
                <!--eslint-enable--> 
                <h5 class="text-sm text-gray mt-1">開通會員，解鎖更多課程！</h5>
              </div>
              <div class="d-flex align-content-center justify-content-around" >
                <div v-for='(product, index) in productList' v-bind:key='product.ftid'> 
                  <div class="position-relative" @click="onClickProduct(index)">
                    <div v-if="selectPosition == index">
                      <img class="nicole-membercard d-md-none" src="assets/img/img_paymentmethod_phone1.png"> 
                      <img class="nicole-membercard d-none d-md-block" src="assets/img/img_paymentmethod_pad1.png">
                      <div class="nicole-member-center text-center p-2">    
                        <div class="text-brown text-card-title text-simbold pt-1">{{ product.name }}</div>
                        <div class="text-brown text-card-name fw-boldest py-md-1">$ {{ product.price }}</div>
                        <div class="text-brown text-card-small lh-sm">{{ product.description }}</div>
                      </div>
                    </div>
                    <div v-else>
                      <img class="nicole-membercard d-md-none" src="assets/img/img_paymentmethod_phone2.png"> 
                      <img class="nicole-membercard d-none d-md-block" src="assets/img/img_paymentmethod_pad2.png">
                      <div class="nicole-member-center text-center p-2">    
                        <div class="text-light2 text-card-title text-simbold pt-1">{{ product.name }}</div>
                        <div class="text-light2 text-card-name fw-boldest py-md-1">$ {{ product.price }}</div>
                        <div class="text-light2 text-card-small lh-sm">{{ product.description }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="text-right">
              <button type="button" class="btn btn-warning rounded-pill w-30 text-sm me-2" :class="(ulid != 'level4') ? '' : 'disabled'" @click="showPopup()">立即開通</button>
            </div>
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div class="mt-3 " v-html="autoRenewalStatement"> </div>
        <!-- eslint-enable -->
        <div class="text-center mt-5">
          <h5 class="text-brown text-card-title text-simbold">~ 開通 VIP 會員享有 ~</h5>
          <div class="d-flex mt-4">
            <div class="col-4">
              <img src="assets/img/ic_vip_level.png" class="img-fluid avatar avatar-l">
              <h5 class="text-brown text-card-small text-simbold mt-1">自訂課程</h5>
            </div>             
            <div class="col-4">
              <img src="assets/img/ic_vip_reading.png" class="img-fluid avatar avatar-l">
              <h5 class="text-brown text-card-small text-simbold mt-1">課程學習無上限</h5>
            </div>             
            <div class="col-4">
              <img src="assets/img/ic_vip_audio.png" class="img-fluid avatar avatar-l">
              <h5 class="text-brown text-card-small text-simbold mt-1">影音專區</h5>
            </div>    
          </div>             
          <div class="d-flex mt-4">
            <div class="col-4">
              <img src="assets/img/ic_vip_save.png" class="img-fluid avatar avatar-l">
              <h5 class="text-brown text-card-small text-simbold mt-1">收藏功能</h5>
            </div>             
            <div class="col-4">
              <img src="assets/img/ic_vip_download.png" class="img-fluid avatar avatar-l">
              <h5 class="text-brown text-card-small text-simbold mt-1">下載音檔</h5>
            </div>             
            <div class="col-4">
            </div>    
          </div>    
        </div>  
        <div class="nicolespace"></div>
        <div class="nicolespace"></div>
      </div>
    </div>
  </div>

    <!-- footer -->
    <!-- End footer -->
    <!-- Modal -->
      <div class="modal fade " id="buy" tabindex="-1" role="dialog" aria-labelledby="buyLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered " role="document">
          <div class="modal-content ">
            <div class="modal-body m-2">
              <h4 class="text-center fw-bold mb-3">正在透過APP Store 購買</h4>
              <h6 class="text-muted text-simbold">方案：{{ modalTitle }}</h6>
              <h6 class="text-muted text-simbold">權益：{{ modalDescription }}</h6>
              <h6 class="text-muted text-simbold">價格：${{ modalPrice }}/{{ modalUnit }}</h6>
              <div class="card bg-light nicole-noshadow my-2 ps-1 pe-3 overflow-auto" style="height: 28vh;">
              <!-- eslint-disable vue/no-v-html -->
              <div class="mt-1 " v-html="autoRenewalAgreement"></div>
              <!-- eslint-enable -->
              </div>
              <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" value="" @click="onClickCheckBox()">
                <label class="custom-control-label text-sm text-muted text-simbold" for="customCheck1">我已充分了解並同意用戶協議，隱私權政策和自動續費服務協議。</label>
              </div>
              <div class="text-danger text-sm">＊ 本產品為數位內容服服務，正式開通後將無法退款，敬請見諒。</div>
            </div>
            <div class="border-top d-flex align-content-center justify-content-between">
              <div class="w-50 text-center border-end py-3" data-bs-dismiss="modal">
                <h5 class="text-success text-simbold mb-0">取消</h5>
              </div>
              <div class="w-50 text-center py-3">
                <div v-if="isCheckBox">
                  <h5 class="text-success text-simbold mb-0" @click="onClickPurchase()">繼續購買</h5>
                </div>
                <div v-else>
                  <h5 class="text-simbold mb-0">繼續購買</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


<!-- Modal -->
<div class="modal fade " id="notice7" tabindex="-1" role="dialog" aria-labelledby="notice7Label" aria-hidden="true">
  <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content nicole-modal"> 
      <div class="modal-body text-n text-simbold  text-dark m-2 text-center lh18">
        <div class="text-center  mb-2">
          <img src="assets/img/gift.png" class="avatar avatar-nicole-m">
        </div>
        選擇年會員<br>才能獲得推薦好禮喔!
      </div>
      <div class="border-top d-flex align-content-center justify-content-between">
        <div class="w-50 text-center border-end py-3">
          <h5 class="text-success text-simbold mb-0" data-bs-dismiss="modal">重新選擇</h5>
        </div>
        <div class="w-50 text-center py-3">
          <h5 class="text-success text-simbold mb-0 " data-bs-dismiss="modal" @click="openModal()">繼續訂閱</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal --> 
<div class="modal fade " id="notice7text" tabindex="-1" role="dialog" aria-labelledby="notice7textLabel" aria-hidden="true">
  <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content nicole-modal">
      <div class="modal-body text-n text-simbold  text-dark m-2 text-center text-simbold">
        <div class="text-center mb-2">
          <img src="assets/img/friend.png" class="avatar avatar-nicole-m">
        </div>
        填寫好友推薦碼：
        <input type="text" class="form-control mt-3" placeholder=" " aria-label="" aria-describedby="basic-addon1" v-model="friendShareCode">
      </div>
      <div class="border-top d-flex align-content-center justify-content-between">
        <div class="w-50 text-center border-end py-3">
          <h5 class="text-success text-simbold mb-0" data-bs-dismiss="modal" @click="openModal()">略過</h5>
        </div>
        <div class="w-50 text-center py-3">
          <h5 class="text-simbold mb-0 " v-if="friendShareCode == ''" >繼續訂閱</h5>
          <h5 class="text-success text-simbold mb-0 " data-bs-dismiss="modal" v-else @click="checkShareCode()" >繼續訂閱</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal --> 


    <!-- End footer -->    

  </body>
  <Footer/>
</div>
</template>

<script>
import swal from "sweetalert2";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import CommonAlert1 from '@/components/CommonAlert1.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 

export default {
    name: 'UpgradeVip',
    components: {
        Header,
        Footer,
        CommonAlert1,
        CommonAlert2Func,
    },
    data () {
      return { 
        // api setting
        setDebugMode:true ,
        apiDebug: false,
        apiName: "",
        perPage: 1,  //筆數
        pageMode: false,
        perPageOptions: [5, 10, 25, 50 , 75 , 100],
        filterData: {},
        form: {},

        // data
        name: "",
        ulid: "",
        userLevelName: "",
        icon: "",
        tempIcon: "",
        autoRenewalAgreement: "", // 續費服務協議
        autoRenewalStatement: "", // 續費服務聲明
        productList: [],

        modalTitle: "",
        modalDescription: "",
        modalPrice: "",
        modalUnit: "",

        friendShareCode: "",

        // status
        selectPosition: 0,
        isCheckBox: false,

        // alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,
      }
    },
    methods: {
      openLoginAlert(){
          this.title = '完成註冊';
          this.content = '為你留下學習的紀錄';
          this.btnNameUp = '馬上註冊';
          this.btnNameDown = '繼續逛逛';
          this.onFuncUp = this.openLogin; 
          this.onFuncDown = null;         
          this.$refs.commonAlert2Func.onShow();
      },
      openLogin(){
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"ContactService", type:""}), this.openLoginCallback);
      },
      openLoginCallback(){},
      openEssayReadCallback(data){},
      setOnBack(){
          this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){

        var backUrl = "";
        var native = "";
        var data = {};
        if (this.$route.query && this.$route.query.backUrl) {
          data = this.$route.query;
          if (this.$route.query.backUrl != "" && this.$route.query.backUrl != undefined ){
            backUrl = this.$route.query.backUrl;
          }
        }
        if (this.$route.params && this.$route.params.backUrl) {
          data = this.$route.params;
          if (this.$route.params.backUrl != "" && this.$route.params.backUrl != undefined ){
            backUrl = this.$route.params.backUrl;
          }
        }
        if (this.$route.query && this.$route.query.native) {
          native = this.$route.query.native
        }
        if (this.$route.params && this.$route.params.native) {
          native = this.$route.params.native
        }


        if(native != ""){
          if(native.indexOf("teacher_explain") > -1){
              this.$JsBridge.callHandler('openTeacherExplain',JSON.stringify({
                  backUrl: backUrl,
                  mode: "vue",
                  type: "back",
              }), this.openTeacherExplainCallback);
          }else if(native.indexOf("essay_read") > -1){
              this.$JsBridge.callHandler('openEssayRead',JSON.stringify({
                  backUrl: backUrl,
                  mode: "vue",
                  type: "back",
              }), this.openEssayReadCallback);
          }
        }else{
          if(backUrl != ""){
            if (backUrl.indexOf("TaskList") > -1  && data.lsid) {
              this.$router.push({
                name: backUrl,
                query: { lsid: data.lsid }
              });
            }else if (backUrl.indexOf("Mine") > -1) {
              this.$router.push({
                name: backUrl
              });
            }else if (backUrl.indexOf("WordPreview") > -1) {
              var backMode = "";
              if (this.$route.query && this.$route.query.backMode) {
                if (this.$route.query.backMode != "" && this.$route.query.backMode != undefined ){
                  backMode = this.$route.query.backMode;
                }
              }
              if (this.$route.params && this.$route.params.backMode) {
                if (this.$route.params.backMode != "" && this.$route.params.backMode != undefined ){
                  backMode = this.$route.params.backMode;
                }
              }
              var name = backUrl.substring(0, backUrl.indexOf("?"));
              var params = {};
              var queryStr = backUrl.substring(backUrl.indexOf("?") + 1, backUrl.length);
              if(queryStr != ""){
              const urlSearchParams = new URLSearchParams(queryStr);   
                  params = Object.fromEntries(urlSearchParams.entries()); 
              }
              if(params && params != null && params != undefined && params.backUrl, params.backUrl != "" && params.backUrl != undefined){
                params.backUrl = atob(params.backUrl);
              }
              // console.log(`params ==> ${JSON.stringify(params)}`);
              // console.log(`backMode ==> ${backMode}`);
              params.backMode = backMode;
              this.$router.push(
              {
                  name : name,
                  params: params,
              });
            }else{
              var tmpBackUrl = backUrl;
              var base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
              if(base64.test(tmpBackUrl)){
                tmpBackUrl = atob(tmpBackUrl);
              }
              var tempName = tmpBackUrl;
              var tempQueryStr = "";
              var tempQuery = {};
              if(tempName.indexOf("?") > -1){
                tempName = tmpBackUrl.substring(0, tmpBackUrl.indexOf("?"));
                tempQueryStr = tmpBackUrl.substring(tmpBackUrl.indexOf("?") + 1, tmpBackUrl.length);
                if(tempQueryStr != ""){
                  const urlSearchParams = new URLSearchParams(tempQueryStr);   
                  tempQuery = Object.fromEntries(urlSearchParams.entries()); 
                }
              }
              // console.log(`tempName : ${tempName}`);
              // console.log(`tempQueryStr : ${tempQueryStr}`);
              // console.log(`tempQuery : ${JSON.stringify(tempQuery)}`);            
              // console.log(`tmpBackUrl : ${tmpBackUrl}`);
              if(tempName.indexOf("word_preview") > -1 || tempName.indexOf("teacher_explain") > -1 || tempName.indexOf("essay_read") > -1){
                var url = `${tempName}?${tempQueryStr}`;
                // console.log(`url ==> ${url}`);
                this.$JsBridge.callHandler('openWebView', url, this.openWebViewCallback);
              }else{
                this.$router.push(backUrl);
              }
            }
          }
          else {
            this.$router.push({
              name: "Home"
            });
          }
        }
      },
      openTeacherExplainCallback(){},
      openOrderRecord(){
        this.$router.push({
          name: "OrderRecord",
          params: {
            back: "UpgradeVip"
          }
        });
      },
      getPageUpgradeVip(){
        this.apiName="page_upgrade_vip";

        this.filterData['uid'] = this.$GlobalData.uid;

        if (this.$JsBridge.isAndroid()) {
          this.filterData["device"] = "android";
        } else if (this.$JsBridge.isiOS()) {
          this.filterData["device"] = "IOS";
        } else {
          this.filterData["device"] = "broser";
        }

        if (this.$route.query.device != undefined) {
          this.filterData["device"] = this.$route.query.device;
        }


        oAC.getAllRecord(oAC.getApiParam(this), this.filterData).then(res => {
          if (res != undefined && res.user != null && res.user.length > 0) {
            if (res.user[0].name != "") {
              this.name = res.user[0].name;
            } else {
              this.name = "Student";
            }
            if (res.user[0].english_level_name != "") {
              this.userLevelName = res.user[0].english_level_name;
            }
            if(res.user[0].image_url != ""){
              if(config && config.old_images && config.old_images=="Y"){ 
                this.tempIcon = res.user[0].image_url;
                this.getUserIcon();
              } 
              else { 
                this.icon = res.user[0].image;
              }
              this.getUserIcon();
            }
            if (res.user[0].ulid != "") {
              this.ulid = res.user[0].ulid;
            }
          }

          if (res != undefined && res.auto_renewal_agreement != null && res.auto_renewal_agreement.length > 0){
            this.autoRenewalAgreement = res.auto_renewal_agreement[0].description;
          }
          if (res != undefined && res.auto_renewal_statement != null && res.auto_renewal_statement.length > 0){
            this.autoRenewalStatement = res.auto_renewal_statement[0].description;
          }

          if (res != undefined && res.product != null && res.product.length > 0){
            this.productList = res.product;
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },
      onClickProduct(index){
        this.selectPosition = index;
      },
      showPopup(){
        // ZEN-2761 先註解
        // ZEN-3276 開啟
        this.friendShareCode = "";
        if (this.selectPosition < this.productList.length && this.productList[this.selectPosition].ptstid == 3) {
          $("#notice7text").modal("show");
        } else {
          $("#notice7").modal("show");
        }
      },
      openModal(){
        this.modalTitle = this.productList[this.selectPosition].name;
        this.modalDescription = this.productList[this.selectPosition].rights_description;
        this.modalPrice = this.productList[this.selectPosition].price;
        this.modalUnit = this.productList[this.selectPosition].product_name.substr(0,1);
        $("#buy").modal('show');
      },
      closeModal(target){
        $(target).modal("hide");
      },
      onClickCheckBox(){
        if (this.isCheckBox) {
          this.isCheckBox = false;
        } else {
          this.isCheckBox = true;
        }
      },
      onClickPurchase(){
        var json = {
          uid: this.$GlobalData.uid+"", 
          ptid: this.productList[this.selectPosition].ptid +"", 
          price: this.productList[this.selectPosition].price+"", 
          android_productid:this.productList[this.selectPosition].product_number_android+"", 
          ios_productid:this.productList[this.selectPosition].product_number_ios+"" 
        };
        
        this.$JsBridge.callHandler('iabSubscribeVue',JSON.stringify( json ), this.subscribeCallback);
      },
      subscribeCallback(jsonStr){
        if(jsonStr != null && jsonStr != undefined && jsonStr != ""){
          if (jsonStr == "Y") {
            // ZEN-2761 先註解
            // ZEN-3276 開啟
            if (this.selectPosition < this.productList.length && this.productList[this.selectPosition].ptstid == 3 && this.friendShareCode != "") {
              this.addedShareCode();
            } else {
              this.getUserData();
            }
          } 
          else {
            var decodeJsonStr = decodeURIComponent(atob(jsonStr));
            var json = JSON.parse(decodeJsonStr);
            $("#buy").modal('hide');
            //購買失敗跳視窗
            if ( json['errorMessage'].indexOf("already") >= 0){
              this.title = "重複訂閱";
              this.content = "此內購帳號已訂閱，無法重複使用。如欲變更方案，請至下方連結做變更。";
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
            } 
            // 找不到購買項目, 你已經擁有這項商品 :
            // Google 會跳出「Fatal error during the API action.」；Apple 會跳出「」
            else if ( json['errorMessage'].indexOf("error") >= 0){
              this.title = "錯誤";
              this.content = json['errorMessage'];
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
            }
            else if ( json['errorMessage'].indexOf("product") >= 0){
              this.title = "錯誤";
              this.content = "找不到產品ID" + this.productList[this.selectPosition].ptid;
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
            }
            else {
              this.title = "";
              this.content = "已取消";
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
            }
          }
        }
      },
      getUserIcon(){
        if(this.tempIcon != ""){
          this.apiName = "page_mine_icon";
          oAC.getAllRecord(oAC.getApiParam(this), {url:this.tempIcon}).then(res => {
              if(res && res.image && res.image != ""){                        
                  this.icon = "data:image/jpeg;base64," + res.image;
              } else {
                  this.icon = "";
              }
            }
          ).catch((err)=> {
              this.icon = "";
              oltConsole.log(err);
          });
        }
      },
      checkShareCode(){
        oltConsole.log("checkShareCode");
        console.log("checkShareCode");


        oltConsole.log("this.friendShareCode: " + this.friendShareCode);
        console.log("this.friendShareCode: " + this.friendShareCode);

        this.apiName="page_upgrade_vip_check";
        this.filterData['uid'] = this.$GlobalData.uid;
        if (this.$JsBridge.isAndroid()) {
          this.filterData["device"] = "android";
        } else if (this.$JsBridge.isiOS()) {
          this.filterData["device"] = "IOS";
        } else if (this.$JsBridge.isBrowser()) {
          // this.filterData["device"] = "broser";
        }
        this.filterData['share_code'] = this.friendShareCode;

        oAC.getAllRecord(oAC.getApiParam(this), this.filterData).then(res => {


          oltConsole.log("checkShareCode Success");
          console.log("checkShareCode Success");

          if (res != undefined && res.user != null && res.user.length > 0) {
            this.openModal();
          }


        }).catch((err)=> {
          oltConsole.log("checkShareCode Error");
          console.log("checkShareCode Error");

          if (err != undefined && 
            err.response != undefined && 
            err.response.data != undefined && 
            err.response.data.error != undefined && 
            err.response.data.error.length > 0) {

            this.friendShareCode = "";
            this.title = '';
            this.btnNameUp = '繼續訂閱';
            this.onFuncUp = this.openModal;
            if (err.response.data.error == "ZEN-3749") {
              this.content = "您好，前次訂閱已發送過獎勵，系統將不重複發送。已發送的獎勵可至我的>推薦好友>領取推薦獎勵查看。";
              this.btnNameDown = '取消訂閱';
              this.onFuncDown = this.closeModal("#notice7text");
            } else {
              this.content = err.response.data.error;
              this.btnNameDown = '更換推薦碼';
              this.onFuncDown = this.showPopup;
            }
            this.$refs.commonAlert2Func.onShow();
          }
          oltConsole.log(err);
        });
      },
      addedShareCode(){
        this.form = {
          uid : this.$GlobalData.uid,
          share_code: this.friendShareCode
        };
        this.apiName="history_post_history_used_share_code";
        oAC.postRecord(oAC.getApiParam(this)).then(res => {
          // if (res != undefined && res.error != null && res.error.length > 0) {
          //   if (res.error == "此推薦碼已填寫") {
          //     this.getUserData();
          //   } else {
          //     this.title = "錯誤";
          //     this.content = res.error;
          //     this.btnNameUp = "確定";
          //     this.$refs.commonAlert1.onShow();  
          //   }
          // } else if (res != undefined && res.history_used_share_code != null) {
          //   this.getUserData();
          // }
          this.getUserData();
        }).catch((err)=> {
          oltConsole.log(err);
          if (err != undefined && 
              err.response != undefined && 
              err.response.data != undefined && 
              err.response.data.error != undefined && 
              err.response.data.error.length > 0) {
            
            if (err.response.data.error == "此推薦碼已填寫") {
              // this.title = "提示";
              // this.content = "已填寫過推薦碼，故不再提供優惠";
              // this.btnNameUp = "確定";
              // this.$refs.commonAlert1.onShow();
            }
            this.getUserData();
          }
        });
      },
      openWebVue(url){
        this.$JsBridge.callHandler('openWebVue',url, this.openWebVueCallback);
      },
      openWebVueCallback(){
      },
      openHome(){
        this.$router.push({
          name: "Home"
        });
      },
      getUserData(){
        this.apiName="member_get_user_data";
        oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid} ).then(res => {
          if (res != undefined && res.record != null && res.rt != null ) {
            this.setUserData(res);
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },
      setUserData(res){
        var json = {
          record: res.record,
          rt: res.rt,
          token: this.$GlobalData.token
        };
        this.$GlobalData.user = res.rt.user;
        this.$JsBridge.callHandler('setUserData',JSON.stringify( json ), this.setUserDataCallback);
      },
      setUserDataCallback(){
        $("#buy").modal('hide');
        this.getPageUpgradeVip();
        this.$forceUpdate();
      },
      unitTest(){
       oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid} ).then(res => {
          if (res != undefined && res.record != null && res.record.length > 0 && res.rt != null && res.rt.user != null) {
            console.log("CC: " + res.record[0].cid);
            console.log("DD: " + res.rt.user[0].uid);
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });        
      },
      openWebViewCallback(){}


    },
    created(){

      if (this.$route.query.uid != undefined) {
        this.$GlobalData.uid = this.$route.query.uid;
      }
      if (this.$route.query.device != undefined) {
        this.device= this.$route.query.device;
      }
      if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }
      this.getPageUpgradeVip();
      window["openWeb"] = this.openWebVue;
    },
    mounted () {
      window.openWebVue = this.openWebVue;
    }
};
</script>
<style type="text/css">
  .input_color{
    border: transparent !important;
  }
  /*隱藏文字區塊的縮放三角形*/
  .input_textarea{
    resize: none;
    min-height: 32vw !important;
  }
  /*隱藏輸入數字的上下箭頭*/
  /* Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  input:focus{outline:none}
</style>